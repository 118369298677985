import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";

const FooterLink = ({ handleUrl, icon: Icon, label, isActive, image }) => {
  const { t } = useTranslation();

  return (
    <div
      onClick={handleUrl}
      className={clsx(
        "d-flex flex-column align-items-center cursor-pointer w-100 position-relative",
        isActive ? "text-danger" : "text-white"
      )}
    >
      {image ? (
        <img
          alt="icon"
          src={image}
          className="position-absolute translate-middle top-50 start-50 bg-white bg-opacity-10 rounded-3"
          style={{ width: "65px" }}
        />
      ) : (
        <Icon size={28} />
      )}
      <span className="pt-2 fw-bolder" style={{ fontSize: "0.7rem" }}>
        {!image && t(label)}
      </span>
    </div>
  );
};

export default FooterLink;
